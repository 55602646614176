.opg-text--secondary {
  color: $govuk-secondary-text-colour;
}

.opg-text--success {
  color: govuk-colour("green");
}

.opg-text--danger {
  color: govuk-colour("red");
}

.opg-text--wrap {
  word-break: break-all;
}
