.opg-indented-block {
  margin-top: govuk-spacing(3);
  margin-bottom: govuk-spacing(3);
  padding: govuk-spacing(3);
  clear: both;
  border-left: 5px solid $govuk-border-colour;

  & :first-child {
    margin-top: 0;
  }
  & :last-child {
    margin-bottom: 0;
  }
}
