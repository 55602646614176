// =======================================================
// HELPERS

// scss-lint:disable SpaceBeforeBrace, SpaceAfterPropertyColon
// Special formatting here

// Negating spacing
// Remove margins
.flush {
  margin: 0 !important;
}
.flush--top {
  margin-top: 0 !important;
}
.flush--right {
  margin-right: 0 !important;
}
.flush--bottom {
  margin-bottom: 0 !important;
}
.flush--left {
  margin-left: 0 !important;
}
.flush--ends {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.flush--sides {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

// Remove paddings
.hard {
  padding: 0 !important;
}
.hard--top {
  padding-top: 0 !important;
}
.hard--right {
  padding-right: 0 !important;
}
.hard--bottom {
  padding-bottom: 0 !important;
}
.hard--left {
  padding-left: 0 !important;
}
.hard--ends {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.hard--sides {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

// Adding spacing
// Add margins
.push {
  margin: $gutter !important;
}
.push--top {
  margin-top: $gutter !important;
}
.push--right {
  margin-right: $gutter !important;
}
.push--bottom {
  margin-bottom: $gutter !important;
}
.push--left {
  margin-left: $gutter !important;
}
.push--ends {
  margin-top: $gutter !important;
  margin-bottom: $gutter !important;
}
.push--sides {
  margin-right: $gutter !important;
  margin-left: $gutter !important;
}

.push-half {
  margin: $gutter-half !important;
}
.push-half--top {
  margin-top: $gutter-half !important;
}
.push-half--right {
  margin-right: $gutter-half !important;
}
.push-half--bottom {
  margin-bottom: $gutter-half !important;
}
.push-half--left {
  margin-left: $gutter-half !important;
}
.push-half--ends {
  margin-top: $gutter-half !important;
  margin-bottom: $gutter-half !important;
}
.push-half--sides {
  margin-right: $gutter-half !important;
  margin-left: $gutter-half !important;
}

.push-double {
  margin: $gutter * 2 !important;
}
.push-double--top {
  margin-top: $gutter * 2 !important;
}
.push-double--right {
  margin-right: $gutter * 2 !important;
}
.push-double--bottom {
  margin-bottom: $gutter * 2 !important;
}
.push-double--left {
  margin-left: $gutter * 2 !important;
}
.push-double--ends {
  margin-top: $gutter * 2 !important;
  margin-bottom: $gutter * 2 !important;
}
.push-double--sides {
  margin-right: $gutter * 2 !important;
  margin-left: $gutter * 2 !important;
}

// Add padding
.soft {
  padding: $gutter !important;
}
.soft--top {
  padding-top: $gutter !important;
}
.soft--right {
  padding-right: $gutter !important;
}
.soft--bottom {
  padding-bottom: $gutter !important;
}
.soft--left {
  padding-left: $gutter !important;
}
.soft--ends {
  padding-top: $gutter !important;
  padding-bottom: $gutter !important;
}
.soft--sides {
  padding-right: $gutter !important;
  padding-left: $gutter !important;
}

.soft-half {
  padding: $gutter-half !important;
}
.soft-half--top {
  padding-top: $gutter-half !important;
}
.soft-half--right {
  padding-right: $gutter-half !important;
}
.soft-half--bottom {
  padding-bottom: $gutter-half !important;
}
.soft-half--left {
  padding-left: $gutter-half !important;
}
.soft-half--ends {
  padding-top: $gutter-half !important;
  padding-bottom: $gutter-half !important;
}
.soft-half--sides {
  padding-right: $gutter-half !important;
  padding-left: $gutter-half !important;
}

// Move elements (experimental)
.move-quarter--up {
  position: relative;
  top: -$gutter-one-quarter;
}

// Text alignment
.text--left {
  text-align: left !important;
}
.text--center {
  text-align: center !important;
}
.text--right {
  text-align: right !important;
}

// Text colours
.text-red {
  color: $govuk-error-colour;
}

// Percentage widths
.width-full {
  width: 100%;
}
.width-three-quarters {
  width: $three-quarters;
} // 75%
.width-two-thirds {
  width: $two-thirds;
} // 66.67%
.width-half {
  width: $half;
} // 50%
.width-third {
  width: $one-third;
} // 33.34%
.width-quarter {
  width: $one-quarter;
} // 25%
.width-fifth {
  width: $full-width / 5;
} // 20%
.width-sixth {
  width: $full-width / 6;
} // 16.67%
.width-seventh {
  width: $full-width / 7;
} // 14.29%
.width-eighth {
  width: $full-width / 8;
} // 12.5%
.width-tenth {
  width: $full-width / 10;
} // 10%
.width-twentieth {
  width: $full-width / 20;
} // 5%

// scss-lint:enable SpaceBeforeBrace, SpaceAfterPropertyColon

.no-wrap {
  white-space: nowrap;
}

.proper-case {
  text-transform: capitalize;
}

// Proactively override as this needs to trump
.right {
  float: right !important;
}
.left {
  float: left !important;
}

// Clear floats
.cl {
  clear: left;
}
.cr {
  clear: right;
}

// Simple 1px border
.border-bottom {
  border-bottom: 1px solid $border-colour;
}

.border-top {
  border-top: 1px solid $border-colour;
}

// Negating borders
.no-border {
  border: 0;

  td {
    border: 0;
  }
}

.no-border-bottom {
  border-bottom: 0;

  td {
    border-bottom: 0;
  }
}

// Handling long words and URLs
// https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
.dont-break-out {
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  overflow-wrap: break-word;
}

// Table cells alignment
.align--top {
  vertical-align: top;
}

.align--bottom {
  vertical-align: bottom;
}

// Background colors
.admin-users {
  background-color: #e6db74;
}
