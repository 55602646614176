// ==========================================================================
// Emails
// Additional formatting on emails page

.email-details {
  margin-bottom: 3rem;
}

.email-summary {
  display: block;
}
