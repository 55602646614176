// Tables
// ==========================================================================

table {
  // A 19px numeric option. Using -small for typographic consistency
  // Allow a qualifying element, only table data cells should use tabular numbers
  td.numeric-small {
    // sass-lint:disable-line no-qualifying-elements
    @include core-19($tabular-numbers: true);
    text-align: right;
  }
}

.grandtotals-border {
  border-top: 2px solid $border-colour;

  &--transparent {
    border-top: 2px solid transparent;
  }
}
