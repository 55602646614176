.opg-submit-link {
  &--disabled {
    pointer-events: none;
    cursor: default;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
  /* Makes link translucent */
}
