// ==========================================================================
// BUTTONS
// Buttons and links

.opg-button--link {
  background-color: transparent;
  color: $govuk-link-colour !important;
  text-decoration: underline !important;
  box-shadow: none;

  &:hover {
    background-color: transparent;
    color: $govuk-link-hover-colour !important;
  }

  &:active,
  &:focus {
    top: initial;
    background-color: transparent;
    color: $govuk-link-active-colour !important;
  }
}

.button-secondary {
  @include button(govuk-colour("light-grey"));

  &[disabled="disabled"] {
    background: govuk-colour("light-grey");
  }
}

.button-warning {
  @include button($red);

  &[disabled="disabled"] {
    background: $red;
  }
}

.button-link {
  @include media(mobile) {
    margin-left: 0;
  }

  position: relative;
  top: 1px;
  margin-left: 20px;
  line-height: 2.5;
}

.action-link {
  &:visited {
    color: $link-colour;
  }
}
