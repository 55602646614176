// Layout
// ==========================================================================

.opg-main-wrapper {
  position: relative;
}

.opg-width-container--wider {
  @include govuk-width-container(1200px);
}
