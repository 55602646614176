.opg-session-timeout {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $black;
  background-color: fade-out($black, 0.7);
  z-index: 9999;

  &__dialog {
    width: 480px;
    margin: 100px auto 0;
    padding: 30px;
    border: 10px solid $black;
    background-color: $white;
    opacity: 1;
  }
}
