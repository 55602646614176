.opg-cookie-banner {
  padding: 20px 0;
  background-color: govuk-colour("white");
  color: govuk-colour("green");
  box-sizing: border-box;

  &__text {
    color: govuk-colour("green");
  }

  &__button {
    margin-bottom: 0;
    border-color: govuk-colour("green");
    background-color: govuk-colour("white") !important;
    color: govuk-colour("green") !important;
    box-shadow: none;
  }

  &__button:hover {
    background-color: govuk-colour("light-grey") !important;
    text-decoration: none;
  }
}
