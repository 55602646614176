// Shame CSS
// ==========================================================================

// Enable standard GDS typography on body elements. Most components automatically
// apply the typography, but some (including custom ones) don't. This ensures the
// font is consistently applied anyway.
body {
  @include govuk-typography-common;
}

// Undo the font-weight nullification in govuk-elements. It's no longer present in
// the Design System, but so eager that it's still cascading through.
b,
strong {
  font-weight: 600;
}

// Undo the hr background colour in govuk-elements. Because `govuk-section-break`
// provides a border, having both makes the <hr>s too tall.
hr {
  background-color: transparent;
}

// .hidden is used in a couple of places to manually hide something from the page
// .js-hidden is used by some of our JavaScript modules to show/hide content
.hidden,
.js-enabled .js-hidden {
  display: none;
  visibility: hidden;
}

// combining .js-enabled and .js-only (see moj_template script tag) displays javascript
// dependent elements
.js-enabled .js-only {
  display: block;
}

// .js-only is used to hide elements that rely on javascript to display or function
// correctly
.js-only {
  display: none;
}
